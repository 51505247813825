<template>
    <section id="content">

        <div class="content-wrap py-0 overflow-visible">

            <div v-for="app in apps" :key="app.id" class="card mx-auto mw-md border-0 shadow rounded-xl card-seo-about mb-5 mb-lg-0" :style="{  'background-image': app.background +' !important'}">
                <div class="header_bg_white"></div>
                <div class="card-body p-4">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="heading-block border-bottom-0 mb-0">
                                <div class="badge badge-pill badge-default"><i class="icon-appstore"></i> iOS App</div>
                                <div class="badge badge-pill badge-default ml-2">
                                  <a :href="app.privacy" target="_blank" style="color:white;">Privacy Policy</a>
                                </div>
                                <div class="badge badge-pill badge-default ml-2">
                                  <a :href="app.terms" target="_blank" style="color:white;">Terms of Use</a>
                                </div>
                                <h3 class="nott ls0 mb-3">{{app.title}}</h3>
                                <a :href="app.url" target="_blank" class="m-0 ls0 row">
                                    <img style="width: 150px; height: 50px" src="https://pngimage.net/wp-content/uploads/2018/05/available-app-store-png-2.png">

                                </a>
                            </div>
                        </div>
                        <div class="d-none d-sm-none d-md-block col-md-4">

                        </div>
                        <div class="col-md-3">
                         <img class="icon-app" :src="app.icon">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Apps",

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Apps',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        data(){
            return {
                apps: [
                    {
                        id:'1',
                        icon: require('@/assets/portfolio/icon-1.png'),
                        title: 'Voice Changer - Recorder',
                        url: 'https://apps.apple.com/us/app/voice-changer-recorder/id1608514533',
                        terms: 'https://voiceee.develoapper.xyz/terms.html',
                        privacy:'https://voiceee.develoapper.xyz/privacy.html',
                      //  screen: require('@/assets/portfolio/8.png'),
                        'background':'linear-gradient(rgb(96 196 139), rgb(0 166 81))'
                    },
                    {
                        id:'2',
                        icon: require('@/assets/portfolio/icon-2.png'),
                        title: 'VPN by Crafty',
                        url: 'https://apps.apple.com/us/app/vpn-by-crafty/id1569411881',
                        terms: 'http://craftyvpn.develoapper.xyz/terms.html',
                        privacy:'http://craftyvpn.develoapper.xyz/privacy.html',
                       // screen: require('@/assets/portfolio/2.png'),
                        'background':'linear-gradient(rgb(96 196 139), rgb(0 166 81))'
                    },
                    {
                        id:'3',
                        icon:  require('@/assets/portfolio/icon-3.png'),
                        title: 'Crafty Skins for Minecraft',
                        url: 'https://apps.apple.com/us/app/skins-for-minecraft-crafty/id1482495448',
                        terms: 'http://craftyskins.develoapper.xyz/terms.html',
                        privacy:'http://craftyskins.develoapper.xyz/privacy.html',
                       // screen: require('@/assets/portfolio/3.png'),
                        'background':'linear-gradient(rgb(96 196 139), rgb(0 166 81))'
                    },
                    {
                        id:'4',
                        icon:  require('@/assets/portfolio/icon-4.png'),
                        title: 'Screen Recorder - Crafty',
                        url: 'https://apps.apple.com/us/app/screen-recorder-crafty/id1538747211',
                        terms: 'http://craftystream.develoapper.xyz/terms.html',
                        privacy:'http://craftystream.develoapper.xyz/privacy.html',
                        //screen: require('@/assets/portfolio/3.png'),
                        'background':'linear-gradient(rgb(96 196 139), rgb(0 166 81))'
                    },
                    {
                        id:'5',
                        icon:  require('@/assets/portfolio/icon-5.png'),
                        title: 'Crafty Craft for Minecraft',
                        url: 'https://apps.apple.com/us/app/crafty-craft-for-minecraft/id1459232480',
                        terms: 'https://craftycraft.develoapper.xyz/terms.html',
                        privacy:'https://craftycraft.develoapper.xyz/privacy.html',
                        //screen: require('@/assets/portfolio/3.png'),
                        'background':'linear-gradient(rgb(96 196 139), rgb(0 166 81))'
                    },
                ]
            }
        }
    }
</script>

<style scoped>
    .card {
        position: relative;
        overflow: hidden;
        margin-top: 20px;
    }
    .nott, .badge {
        color: white;
    }
    .badge {
        border: 1px solid white;
    }
    .icon-app {
        width: 100px;
        height: 100px;
        position: absolute;
        right: 20px;
        top: -50px;
        border-radius: 10%;
        /* bottom: 0px; */
    }
    .header_bg_white {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        background: #fff;
        transform: skew(
                -47deg
        );
        transform-origin: right bottom;
    }
</style>